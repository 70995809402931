import * as React from "react"
//import { Link, graphql } from "gatsby"
import { graphql } from "gatsby"
import { Link } from "../utils/link"
import { Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"



// see also blog-post.js
const BlogPostCardTemplate = ({ data, location }) => {
  const {t, i18n} = useTranslation()
  const post = data
  const langKey = i18n.language || `ja`
  const title = post.frontmatter?.title || post.fields?.slug || post.title


  return (
    <article
      className="post-list-item"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <h2>
          <Link to={post.fields?.slug ?? post.link} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small>{post.frontmatter?.date ?? post.date}</small>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter?.description ?? post.description,
          }}
          itemProp="description"
        />
      </section>
    </article>
  )

}


export default BlogPostCardTemplate

