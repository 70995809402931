import * as React from "react"
//import { Link, graphql } from "gatsby"
import { graphql } from "gatsby"
// import { Link } from "../utils/link"
import {Link, Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPostCard from "../components/blog-post-card"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Helmet } from "react-helmet"

const TagsPage = ({
  data: {
    tags: { tags },
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const {t, i18n} = useTranslation()
  const langKey = i18n.language || `ja`;

  return (
    <Layout title={title} tags={tags}>
      <Seo
        title={t("NO PREFIX NOTES")}
        lang={langKey}
      />
      <ol style={{ listStyle: `none` }}>
        {group.map(tag => {
          return (
            <li key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
            </li>)
        })}
      </ol>
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    tags: allMarkdownRemark(
      limit: 2000
        filter: {
          fields: {langKey: {eq: $language}},
        }) {
        tags: group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    allMarkdownRemark(
    limit: 2000
      sort: { fields: [frontmatter___date], order: DESC}
      filter: {
        fields: {langKey: {eq: $language}},
        }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`

